.bannerImagesWrapper {
  margin-top: 40px;
  z-index: 20;
  background-image: url(banner_Bg.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
}

@media screen and (max-width: 991px) {
  .bannerImagesWrapper {
    margin-top: 50px;
  }
}