.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.sectionGradient {
  background-image: linear-gradient(#491161, #2f0e4a);
  padding-top: 0;
  position: relative;
}

.layoutGrid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.valuesListCounterGrid {
  z-index: 2;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
}

@media screen and (min-width: 1920px) {
  .valuesListCounterGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .valuesListCounterGrid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .valuesListCounterGrid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .valuesListCounterGrid {
    grid-template-columns: 1fr;
  }
}