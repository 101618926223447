.titleWrapCenter {
  z-index: 20;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: var(--white);
  text-align: center;
  flex-direction: column;
  align-items: center;
  //max-width: 830px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 767px) {
  .titleWrapCenter {
    max-width: 440px;
  }
}

@media screen and (max-width: 991px) {
  .titleWrapCenter {
    max-width: 630px;
  }
}

@media screen and (min-width: 1280px) {
  .titleWrapCenter {
    //max-width: 900px;
  }
}