.solutionsItem {
  border: 1px solid #9f28a1;
  border-radius: 4px;
  justify-content: center;
  width: 30%;
  display: flex;
  overflow: hidden;
}

.solutionItemInnerWrapper {
  background-color: #45115e;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  display: flex;
}

.solutionImage {
  //width: 80px;
  height: 90px;
}

.inlineBlock {
  max-width: 100%;
  display: inline-block;
}

.a {
  background-color: rgba(0, 0, 0, 0);
}

.a:active, .a:hover {
  outline: 0;
}

.a {
  color: var(--primary-reach);
  text-align: left;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .35s;
}

.a:hover {
  opacity: 1;
  color: var(--primary-violet);
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.solutionTitle {
  color: var(--white);
  text-align: center;
  transition: all .2s;

  &:hover {
    color: var(--primary-reach);
  }
}

.paragraphLight {
  color: var(--paragraph-light);
}

.textCenter {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .solutionsItem {
    width: 47%;
  }
}

@media screen and (max-width: 767px) {
  .solutionsItem {
    width: 100%;
  }

  .solutionItemInnerWrapper {
    width: 100%;
  }
}