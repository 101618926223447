.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.section {
  display: block;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.innerBlock {
  padding: 0 15px;
  position: relative;
}

.sectionInnerBlock.imageBackground {
  z-index: 10;
  --blend-top: normal;
  --blend-bottom: multiply;
  --color: grey;
  --color-v: transparent;
  background:
          radial-gradient(circle, transparent 40%, var(--color-v) 75%),
          linear-gradient(to right, var(--color), var(--color)),
          url('Blogue_Tech.jpeg');
  background-size: cover;
  background-blend-mode:
          var(--blend-top, normal),
          var(--blend-bottom, saturation),
          normal;

  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 70px 20px 80px;
  display: block;
  position: relative;
}

.darkBgAbsolute {
  background-color: #2d0949;
  width: 100%;
  position: absolute;
  left: 0%;
  right: 0;

  z-index: 1;
  background-image: linear-gradient(#2d0949, #3b0c56);
  height: 335px;
  top: auto;
  bottom: 0;
}

.sectionTitleWrapper {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  //max-width: 710px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.bannerHeaderDescription {
  color: var(--paragraph-light);
  text-align: center;
  //max-width: 700px;
  font-size: 22px;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .section.innerBlock {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sectionInnerBlock.imageBackground {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  .darkBgAbsolute {
    height: 285px;
  }
}

@media screen and (min-width: 1440px) {
  .section.innerBlock {
    padding-left: 135px;
    padding-right: 135px;
  }
}

@media screen and (min-width: 1920px) {
  .section.innerBlock {
    padding-left: 180px;
    padding-right: 180px;
  }

  .sectionInnerBlock.imageBackground {
    background-size: 1600px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .sectionInnerBlock.imageBackground {
    background-position: 0%;
  }

  .darkBgAbsolute {
    z-index: 1;
  }

  .sectionTitleWrapper {
    max-width: 560px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sectionInnerBlock.imageBackground {
    background-position: 18%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .darkBgAbsolute {
    z-index: 0;
    background-color: #5a1170;
  }

  .sectionTitleWrapper {
    max-width: 440px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .sectionInnerBlock.imageBackground {
    background-size: auto;
  }

  .darkBgAbsolute {
    background-color: #440d5d;
  }
}