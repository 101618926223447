.section {
  display: block;
}

.maxWidthInit {
  max-width: initial;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &.backgroundImage {
    background-image: url('Integrations_Bg.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.integrationImagesWrapper {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.img {
  border: 0;
}

.img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.integrationServiceImage {
  max-width: 100px;
  margin-bottom: auto;
  position: relative;
}

.integrationServiceImage.mt135 {
  margin-top: 135px;
}

.integrationServiceImage.mt90 {
  margin-top: 90px;
}

.integrationServiceImage.mt55 {
  margin-top: 55px;
}

.integrationServiceImage.mt30 {
  margin-top: 30px;
}

@media screen and (min-width: 1280px) {
  .integrationServiceImage {
    max-width: 120px;
  }
}

@media screen and (min-width: 1440px) {
  .integrationServiceImage {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .section.backgroundImage {
    background-position: 50% 69%;
  }

  .integrationServiceImage {
    max-width: 90px;
  }
}

@media screen and (max-width: 767px) {
  .section.backgroundImage {
    overflow: hidden;
  }
}

@media screen and (max-width: 479px) {
  .section.backgroundImage {
    background-position: 50% 80%;
    background-size: auto;
    min-height: auto;
  }

  .integrationImagesWrapper {
    flex-wrap: wrap;
  }

  .integrationServiceImage {
    max-width: 60px;
  }

  .integrationServiceImage.mt135, .integrationServiceImage.mt90 {
    margin-top: 80px;
  }

  .integrationServiceImage.mt55 {
    margin-top: 0;
  }
}