.image {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
  width: 100%;
  overflow: initial;
}

@media screen and (max-width: 479px) {
  .image {
    height: 820px;
  }
}

@media screen and (max-width: 767px) {
  .image {
    height: 710px;
  }
}

@media screen and (max-width: 991px) {
  .image {
    object-fit: cover;
    height: 880px;
  }
}

@media screen and (min-width: 1920px) {
  .image {
    object-fit: cover;
    height: 1280px;
    top: -26%;
  }
}