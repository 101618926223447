.pt0 {
  padding-top: 0;
}

.worldMapWrapper {
  width: 100%;

  & svg {
    width: 100%;
  }
}