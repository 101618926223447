.pt0 {
  padding-top: 0;
}

.accordionWrapper {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.accordionWrap {
  margin-right: 15px;
}

.accordionWrap.lastChild {
  margin-right: 0;
}

@media screen and (max-width: 991px) {
  .accordionWrapper {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .accordionWrapper {
    flex-wrap: wrap;
  }

  .accordionWrap {
    width: 100%;
    margin-right: 0;
  }
}