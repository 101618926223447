.innerBlockGradient {
  opacity: 1;
  filter: blur(130px);
  background-color: #ff31ff;
  border-radius: 500px;
  width: 1000px;
  height: 250px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  &.value {
    background-color: rgba(255, 49, 255, .4);
    top: 10%;
  }
}

@media screen and (max-width: 991px) {
  .innerBlockGradient {
    width: 760px;
  }
}

@media screen and (max-width: 767px) {
  .innerBlockGradient {
    width: 560px;
  }
}

@media screen and (max-width: 479px) {
  .innerBlockGradient {
    width: 320px;
  }
}

@media screen and (min-width: 1920px) {
  .innerBlockGradient {
    filter: blur(190px);
    width: 1400px;
  }
}