.description {
  color: var(--paragraph-light);
  text-align: center;
  //max-width: 700px;
  font-size: 22px;

  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

.dark {
  color: var(--paragraph-dark);
}

.white {
  color: var(--white);
}