.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &.gradientBg {
    background-image: url('solution_intro_bg.webp');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.solutionsWrapper {
  z-index: 10;
  width: 100%;
  position: relative;
}

.solutionsCollectionListWrapper {
  z-index: 10;
  position: relative;
}

.solutionsCollectionList {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.relative {
  z-index: 5;
  position: relative;
}

.bannerImageLaptop {
  z-index: 3;
  //height: 460px;
  margin-top: 50px;
  position: relative;
}

.bannerImage {
  z-index: 10;
  position: relative;
}

.bannerImage.cloud {
  opacity: 1;
}

@media screen and (min-width: 1920px) {
  .section.gradientBg {
    background-position: 50% 100%;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;

    &.gradientBg {
      background-position: 50% -40%;
      //background-size: auto;
    }
  }

  .relative {
    position: relative;
  }

  .bannerImage.cloud {
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 1%;
  }

  .bannerImage.mobHidden {
    position: absolute;
    top: 188px;
    left: -150px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;

    &.gradientBg {
      background-position: 50% 0;
      //background-size: auto 2100px;
    }
  }

  .relative {
    text-align: left;
  }

  .bannerImageLaptop {
    margin-top: 0;
  }

  .bannerImage.cloud {
    display: none;
    top: 11%;
  }

  .bannerImage.board, .bannerImage.mobHidden {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;

    &.gradientBg {
      background-position: 50% 0;
      background-size: cover;
    }
  }

  .relative {
    text-align: left;
  }

  .bannerImageLaptop {
    object-fit: cover;
    //height: 300px;
  }
}