.gridTwoSections {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  width: 100%;
  display: grid;
}

.img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.borderRadius {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  height: 500px;
}

.accordionWrapperOneColumn {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.accordionWrapOneColumn {
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .gridTwoSections {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 991px) {
  .borderRadius {
    max-width: none;
    height: 400px;
  }

  .accordionWrapperOneColumn {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .gridTwoSections {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .borderRadius {
    height: 300px;
  }

  .accordionWrapperOneColumn {
    width: 100%;
  }
}