.footer {
  text-align: center;
  background-image: linear-gradient(#3b0c56 40%, #3b0c56 42%, #30094c);
  align-items: flex-start;
  padding-top: 80px;
  padding-bottom: 30px;
}

.footerWrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 60px;
  display: grid;
}

.a {
  background-color: rgba(0, 0, 0, 0);
}

.a:active, .a:hover {
  outline: 0;
}

.a {
  color: var(--primary-reach);
  text-align: left;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .35s;
}

.a:hover {
  opacity: 1;
  color: var(--primary-violet);
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.footerBrandDescription {
  color: var(--paragraph-light);
  text-align: left;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
}

.wrapLinkColumns {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.footerLinksWrapper {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.footerTitle {
  color: var(--white);
  margin-top: 0;
  margin-bottom: 30px;
}

.contactUsInfoWrapper {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  text-align: left;
  flex-direction: column;
  font-family: Mulish, sans-serif;
  display: flex;
}

.smallText {
  font-size: 16px;

  &.light {
    color: var(--paragraph-light);
  }
}

.footerContactLink {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
}

@media screen and (min-width: 1280px) {
  .footer {
    padding-top: 80px;
  }

  .footerWrapper {
    grid-template-rows: auto;
    grid-template-columns: 317px 1fr 1fr;
  }

  .footerBrandDescription {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .footerWrapper {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    margin-bottom: 50px;
    display: flex;
  }

  .footerBrandDescription {
    text-align: center;
    margin-top: 20px;
  }

  .wrapLinkColumns {
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    justify-content: flex-end;
    padding-right: 0;
    display: flex;
  }

  .footerLinksWrapper {
    align-items: center;
  }

  .footerTitle {
    margin-bottom: 20px;
  }

  .contactUsInfoWrapper {
    align-items: center;
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding-top: 60px;
  }

  .footerWrapper {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: center;
    justify-items: center;
    margin-bottom: 40px;
    display: flex;
  }

  .footerBrandDescription {
    text-align: center;
  }

  .wrapLinkColumns {
    grid-column-gap: 110px;
  }
}

@media screen and (max-width: 479px) {
  .footer {
    text-align: left;
  }

  .footerWrapper {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .wrapLinkColumns {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footerTitle {
    margin-bottom: 20px;
  }

  .contactUsInfoWrapper {
    align-items: center;
  }
}