.layoutFlex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.radio {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;

  &:before, &:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }

  &:after {
    clear: both;
  }
}

.formRadioCustom {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.formRadioCustom.checked {
  border-width: 4px;
  border-color: #3898ec;
}

.checkbox {
  background-color: #fff;
  border-color: rgba(175, 175, 175, .5);
  border-radius: 6px;
  width: 120px;
  height: 80px;
  position: relative;
}

.checkbox.checked {
  background-color: var(--paragraph-light);
  background-image: none;
  border-width: 1px;
  border-color: rgba(175, 175, 175, .5);
}

.checkboxCenter {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.radioInput {
  float: left;
  margin: 3px 0 0 -20px;
  line-height: normal;
}

.radioButtonField {
  position: relative;
}

.checkboxWrap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
  justify-content: space-between;
}

.formInner {
  width: 100%;
}

.textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: auto;
}

textarea.mInput {
  height: auto;
}

.mInput {
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.mInput:-moz-placeholder {
  color: #999;
}

.mInput::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.mInput::-webkit-input-placeholder {
  color: #999;
}

.mInput:focus {
  border-color: #3898ec;
  outline: 0;
}

.mInput[disabled], .mInput[readonly] {
  cursor: not-allowed;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.input {
  color: inherit;
  font: inherit;
  margin: 0;
}

.input {
  line-height: normal;
}

.input {
  color: var(--paragraph-light);
  background-color: #360a53;
  border: 1px solid #9f28a1;
  border-radius: 64px;
  width: 510px;
  height: 56px;
  margin-bottom: 0;
  padding: 13px 20px;
  font-family: Mulish, sans-serif;
}

.input:focus {
  border-color: var(--primary-violet);
}

.input::placeholder {
  color: var(--paragraph-light);
  font-size: 16px;
  line-height: 1.5em;
}

.input.transpatentGray {
  border-color: var(--dark-gray);
  background-color: var(--light-gray);
  color: var(--text-dark);
  width: 100%;
  font-size: 16px;
  line-height: 1.5em;
}

.input.transpatentGray:hover {
  border-color: var(--primary-reach);
}

.input.transpatentGray::placeholder {
  color: var(--text-dark);
  font-size: 16px;
  line-height: 1.5em;
}

.input.transpatentTextareaGray {
  border-color: var(--dark-gray);
  background-color: var(--transparent);
  color: var(--text-dark);
  border-radius: 20px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 200px;
  margin-top: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 1.5em;
}

.input.transpatentTextareaGray:hover {
  border-color: var(--primary-reach);
}

.input.transpatentTextareaGray::placeholder {
  color: var(--text-dark);
  font-size: 16px;
  line-height: 1.5em;
}

.getInTouchSectionTitleWrapper {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  align-items: stretch;
  width: 100%;
}

.formContactLeftSection {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-image: url('IT_services_img.webp'), linear-gradient(357deg, #57136f, #1e0d3a);
  background-position: 100% 100%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 124%, auto;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  padding: 80px 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contactFormWrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: var(--light-gray);
  border: 1px solid rgba(175, 175, 175, .5);
  border-radius: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  min-height: 830px;
  padding: 50px 40px;
  display: grid;
}

.formTitleContentInner {
  z-index: 10;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.contactUsForm {
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
  display: flex;
}

.form {
  margin: 0 0 15px;
}

.inputFieldsGrid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 40px;
  display: grid;
}

.textareaWrapper.contactUs {
  margin-top: 35px;
}

.mb20 {
  margin-bottom: 20px;
}

.white {
  color: var(--white);
}

@media screen and (min-width: 1280px) {
  .getInTouchSectionTitleWrapper {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .formContactLeftSection {
    max-width: 440px;
  }

  .input {
    width: 345px;
  }

  .input.transpatentGray {
    width: 100%;
  }

  .input.transpatentTextareaGray {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .getInTouchSectionTitleWrapper {
    flex-direction: column;
  }

  .formContactLeftSection {
    background-position: 100%, 0 0;
    background-size: contain, auto;
    max-width: 100%;
  }

  .contactFormWrapper {
    min-height: 600px;
    padding-top: 40px;
  }

  .contactUsForm {
    width: 100%;
  }

  .input:focus {
    border-color: var(--primary-reach);
  }
}

@media screen and (max-width: 767px) {
  .getInTouchSectionTitleWrapper {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .contactFormWrapper {
    min-height: 400px;
    padding: 40px 20px;
  }

  .contactUsForm {
    display: flex;
  }

  .inputFieldsGrid {
    margin-bottom: 30px;
  }

  .input {
    width: 420px;
  }

  .textareaWrapper.contactUs {
    margin-top: 25px;
  }

  .checkbox {
    width: 100px;
  }
}

@media screen and (max-width: 479px) {
  .formContactLeftSection {
    padding: 60px 20px;
  }

  .contactFormWrapper {
    grid-template-columns: 1fr;
  }

  .inputFieldsGrid {
    grid-template-columns: 1fr;
  }

  .input {
    width: 320px;
  }

  .input.transpatentGray {
    width: 100%;
  }

  .input.transpatentTextareaGray {
    width: 100%;
  }

  .checkboxWrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .radioButtonField {
    width: 46%;
    padding-left: 0;
  }

  .checkbox {
    width: 100%;
    margin-left: 0;
  }
}