.h2 {
  margin-bottom: 10px;
  font-weight: bold;
}

.h2 {
  color: var(--text-dark);
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 54px;
  font-weight: 800;
  line-height: 1.1em;

  &.white {
    color: var(--white);
  }
}

@media screen and (min-width: 1440px) {
  .h2 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 991px) {
  .h2 {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .h2 {
    font-size: 38px;
    overflow-wrap: anywhere;
  }
}