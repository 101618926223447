.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &.darkBgAbout {
    background-image: linear-gradient(178deg, #2d0949 56%, #340a4f);
    position: relative;
    overflow: hidden;
  }
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.servicesAboutImage {
  width: 100%;
  max-width: 50%;
  height: 585px;
  display: flex;
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: auto;
  right: -110px;
  transform: translate(0, -50%);
}

.rightImage {
  z-index: 2;
  object-fit: contain;
  width: 100%;
}

.imageBlur {
  filter: blur(70px);
  background-color: #4412ed;
  width: 170px;
  height: 130px;
  position: absolute;
  top: 37%;
  bottom: 0%;
  left: auto;
  right: 37%;
}

.contentWrapperLeft {
  align-self: flex-start;
  width: 60%;
  max-width: 650px;
}

.paragraphLight {
  color: var(--paragraph-light);
}

.mt25 {
  margin-top: 25px;
}

@media screen and (min-width: 1920px) {
  .section.darkBgAbout {
    //background-image: linear-gradient(178deg, #2d0949 29%, #380c54);
  }

  .servicesAboutImage {
    height: 650px;
    right: -6%;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .servicesAboutImage {
    max-width: 100%;
    height: 410px;
    position: static;
    transform: translate(0);
  }

  .rightImage {
    object-fit: contain;
    position: static;
  }

  .contentWrapperLeft {
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .servicesAboutImage {
    height: auto;
  }

  .rightImage {
    position: static;
  }
}