.img {
  border: 0;
}

.img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.section {
  display: block;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.cta {
  background-color: #2d0949;
  background-image: url('getStarted_bg.webp');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0;
}

.ctaTextWrapper {
  flex-direction: column;
  align-items: center;
  //max-width: 530px;
  display: flex;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.white {
  color: var(--white);
}

.center {
  text-align: center;
}

.logos {
  background-image: linear-gradient(rgba(72, 18, 96, 0), #3b0c56 95%);
  border-bottom: 1px solid #6528a1;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
  overflow: hidden;
}

.logosWrap {
  //display: flex;
  //animation: scrollText 50s infinite linear;
}

.logosRow {
  grid-column-gap: 90px;
  grid-row-gap: 90px;
  justify-content: space-between;
  width: 2000px;
  display: flex;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.companyLogo {
  opacity: .6;
  margin-left: 90px;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .logos {
    padding-top: 130px;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .logosRow {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
  }
}

@media screen and (min-width: 1920px) {
  .section.cta {
    background-position: 50% 0;
    background-size: cover;
  }

  .logos {
    overflow: hidden;
  }

  .logosWrap {
    width: 100%;
  }
  
  .logosRow {
    grid-column-gap: 90px;
    grid-row-gap: 90px;
    justify-content: space-around;
    width: 2920px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .ctaTextWrapper {
    max-width: 440px;
  }

  .companyLogo {
    //width: 150px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ctaTextWrapper {
    max-width: 340px;
  }

  .logos {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .companyLogo {
    width: auto;
  }
}