.section {
  display: block;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media screen and (min-width: 1920px) {
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }
}