.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.mt40 {
  margin-top: 40px;
}

.width100p {
  width: 100%;
}

.serviceListBanner {
  background-image: linear-gradient(#45115e, #3b0b56);
  justify-content: center;
  align-items: center;
  padding-top: 155px;
  padding-bottom: 80px;
  display: flex;
}

.img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.bannerImage {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.bannerBackgroundGradient {
  z-index: 1;
  filter: blur(180px);
  background-color: #f265f2;
  border-radius: 700px;
  width: 990px;
  height: 810px;
  position: absolute;
  top: 11%;
  bottom: auto;
  left: 0;
  right: auto;
}

.solutionsCollectionListWrapper {
  z-index: 10;
  position: relative;
}

.solutionsCollectionList {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;

  &.solutionGridItems {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 40px;
    display: grid;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .serviceListBanner {
    background-image: linear-gradient(#45115e, #3b0b56);
    padding-top: 205px;
    padding-bottom: 130px;
  }

  .bannerBackgroundGradient {
    top: 4%;
    left: 12%;
  }
}

@media screen and (min-width: 1440px) {
  .bannerBackgroundGradient {
    top: 17%;
  }
}

@media screen and (min-width: 1920px) {
  .serviceListBanner {
    background-image: linear-gradient(#45115e, #3b0b56);
  }

  .bannerImage {
    object-fit: cover;
    width: 100%;
    height: 1280px;
    top: -26%;
  }

  .bannerBackgroundGradient {
    filter: blur(310px);
    width: 1380px;
    height: 560px;
    top: 12%;
    left: 8%;
  }
}

@media screen and (max-width: 991px) {
  .serviceListBanner {
    padding-top: 135px;
  }

  .section {
    background-color: #fff;
  }

  .bannerImage {
    object-fit: cover;
    height: 880px;
  }

  .bannerBackgroundGradient {
    width: 720px;
    height: 530px;
    top: 32%;
  }

  .solutionsCollectionList.solutionGridItems {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .serviceListBanner {
    padding-top: 105px;
  }

  .bannerImage {
    height: 710px;
  }

  .bannerBackgroundGradient {
    width: 560px;
  }

  .solutionsCollectionList.solutionGridItems {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .bannerImage {
    height: 820px;
  }

  .bannerBackgroundGradient {
    width: 320px;
    top: 36%;
  }
}