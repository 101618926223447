.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &.darkBgWithoutTopSpacing {
    background-color: #2d0949;
    padding-top: 1px;
  }
}

.sectionTitleWrapper {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 710px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.featuresWrapper {
  grid-column-gap: 25px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .featuresWrapper {
    grid-column-gap: 40px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .sectionTitleWrapper {
    max-width: 560px;
  }

  .featuresWrapper {
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sectionTitleWrapper {
    max-width: 440px;
    margin-bottom: 30px;
  }

  .featuresWrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .featuresWrapper {
    grid-template-columns: 1fr;
  }
}