.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &.innerBlock {
    padding: 0 15px;
    position: relative;
  }
}

.sectionInnerBlock.imageBackground {
  z-index: 10;
  --blend-top: normal;
  --blend-bottom: multiply;
  --color: grey;
  --color-v: transparent;
  background:
          radial-gradient(circle, transparent 40%, var(--color-v) 75%),
          linear-gradient(to right, var(--color), var(--color)),
          url('Blogue_Tech.jpeg');
  background-size: cover;
  background-blend-mode:
          var(--blend-top, normal),
          var(--blend-bottom, saturation),
          normal;

  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 70px 20px 80px;
  display: block;
  position: relative;
}

.darkBgAbsolute {
  z-index: -1;
  background-color: #2d0949;
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1440px) {
  .section.innerBlock {
    padding-left: 135px;
    padding-right: 135px;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;

    &.innerBlock {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .sectionInnerBlock.imageBackground {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .sectionInnerBlock.imageBackground {
    background-position: 0;
  }

  .darkBgAbsolute {
    z-index: 1;
    background-color: #54106b;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sectionInnerBlock.imageBackground {
    background-position: 18%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .darkBgAbsolute {
    z-index: 0;
    background-color: #5a1170;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .sectionInnerBlock.imageBackground {
    background-size: auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  .darkBgAbsolute {
    background-color: #440d5d;
  }
}

@media screen and (min-width: 1920px) {
  .section {
    &.innerBlock {
      padding-left: 180px;
      padding-right: 180px;
    }
  }

  .sectionInnerBlock.imageBackground {
    background-position: 50%;
    background-size: 1600px;
  }
}