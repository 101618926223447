.title {
  font-family: Mulish, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2em;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.accordionListContent {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}