.header {
  width: 100%;
  //height: 85px;
  padding: 15px 0;
  text-align: end;
  z-index: 100;
  position: fixed;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.a {
  background-color: rgba(0, 0, 0, 0);
}

.a:active, .a:hover {
  outline: 0;
}

.a {
  color: var(--primary-reach);
  text-align: left;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .35s;

  &:hover {
    opacity: 1;
    color: var(--primary-violet);
  }
}

.inlineBlock {
  max-width: 100%;
  display: inline-block;
}

.container {
  padding: 0 50px;
}

.navContainer {
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.headerLogo {
  //width: 55px;
  //min-width: 55px;
  height: auto;
}

.navMenuWrapper {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navLink {
  padding: 10px 14px 15px;
  font-size: 18px;
  font-weight: 500;

  &:hover {
    opacity: 1;
  }
}

.navWrapInner {
  flex: 1;
  align-self: stretch;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.navItemTitle {
  color: var(--text-dark);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

.navHoverLine {
  background-color: var(--primary-violet);
  width: 100%;
  height: 2px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.desktopItems {
  display: block;
}

.mobileMenuButton {
  float: right;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 18px 0 18px 18px;
  font-size: 24px;
  display: none;
  position: relative;

  &:focus {
    outline: 0;
  }
}

.white {
  color: var(--white);
}

@media screen and (min-width: 1280px) {
  .navContainer {
    padding-left: 50px;
    padding-right: 50px;
  }

  .navLink {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 1920px) {
  .navContainer {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (max-width: 991px) {
  .navContainer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navLink {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 5px;
    display: flex;
  }

  .navWrapInner {
    flex: 0 auto;
  }

  .navHoverLine {
    display: none;
  }

  .mobileMenuButton {
    display: block;
  }

  .desktopItems {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .brand {
    padding-left: 0;
  }

  .navLink {
    margin-left: 0;
  }

  .mobileMenuButton {
    display: block;
  }

  .desktopItems {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .navLink {
    width: 99%;
  }

  .navHoverLine {
    display: none;
  }

  .mobileMenuButton {
    display: block;
  }

  .desktopItems {
    display: none;
  }
}