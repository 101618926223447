.buttonsWrapper {
  z-index: 20;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 479px) {
  .buttonsWrapper {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
  }
}