.valueBlockItem {
  background-color: rgba(255, 255, 255, .05);
  border: 1px solid rgba(255, 255, 255, .2);
  border-radius: 10px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 30px;
  display: flex;
  height: 100%;
}

.mb5 {
  margin-bottom: 5px;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.valuesIcon {
  padding: 5px;
}

.img {
  border: 0;
}

.img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.valuesIconWrapper {
  border: 1px solid var(--paragraph-light);
  background-color: var(--light-gray);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  display: flex;
}

.white {
  color: var(--white);
}

@media screen and (max-width: 479px) {
  .valueBlockItem {
    padding-left: 30px;
    padding-right: 30px;
  }
}