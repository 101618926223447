.featuresWrap {
  text-align: center;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  width: 100%;
  display: flex;
}

.featureDescription {
  color: var(--paragraph-light);
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 16px;
}

.featuresImage {
  height: 200px;
}

.linkWrapper {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  display: flex;

  &:hover * {
    opacity: 1;
    cursor: pointer;
    color: var(--primary-violet);
  }
}

.linkArrow {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-reach);
  text-align: left;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all 0.35s;
}

.linkWithIcon {
  transition: all .3s;
  display: flex;
}

.a {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-reach);
  text-align: left;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .35s;
}

@media screen and (max-width: 479px) {
  .featuresWrap.last {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .featuresWrap {
    width: 100%;

    &.last {
      max-width: 100%;
    }
  }

  .linkWrapper {
    justify-content: flex-start;

    &.center {
      text-align: left;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .featuresWrap.last {
    max-width: 50%;
  }

  .featuresImage {
    height: 180px;
  }
}

@media screen and (min-width: 1280px) {
  .featuresImage {
    height: 250px;
  }
}