.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.gridOfTrust {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.trustInnerWrap {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  display: flex;
}

.bigNumber {
  color: var(--primary-reach);
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 70px;
  font-weight: 800;
  line-height: 1em;
  overflow-wrap: anywhere;
}

.textCenter {
  text-align: center;
}

.dark {
  color: var(--paragraph-dark);
}

.sectionTitleWrapper {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  //max-width: 710px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .bigNumber {
    font-size: 60px;
  }

  .sectionTitleWrapper {
    max-width: 560px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .gridOfTrust {
    grid-template-columns: 1fr 1fr;
  }

  .bigNumber {
    font-size: 50px;
  }

  .sectionTitleWrapper {
    max-width: 440px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .gridOfTrust {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 1fr;
    width: 80%;
  }

  .trustInnerWrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}