.wrapper {
/*  position: relative;
  display: flex;
  justify-content: center;

  background-image: linear-gradient(#571f6a, #2d0949);
  padding-top: 155px;*/
  background-image: linear-gradient(#571f6a, #2d0949);
  //padding-top: 155px;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.baseContainer {
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.bannerBackgroundGradient {
  z-index: 1;
  filter: blur(180px);
  background-color: #f265f2;
  border-radius: 700px;
  width: 990px;
  height: 810px;
  position: absolute;
  top: 11%;
  bottom: auto;
  left: 0;
  right: auto;
}

@media screen and (min-width: 1920px) {
  .wrapper {
    padding-top: 205px;
    overflow: initial;
  }

  .bannerBackgroundGradient {
    filter: blur(310px);
    width: 1380px;
    height: 560px;
    top: 12%;
    left: 8%;
  }
}

@media screen and (min-width: 1440px) {
  .bannerBackgroundGradient {
    top: 17%;
  }
}

@media screen and (min-width: 1280px) {
  .wrapper {
    padding-top: 195px;
    padding-bottom: 130px;
  }

  .bannerBackgroundGradient {
    top: 4%;
    left: 12%;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    padding-top: 135px;
    overflow: initial;
  }

  .bannerImagesWrapper {
    margin-top: 50px;
  }

  .bannerBackgroundGradient {
    width: 720px;
    height: 530px;
    top: 32%;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding-top: 105px;
    padding-bottom: 60px;
  }

  .bannerBackgroundGradient {
    width: 560px;
  }
}

@media screen and (max-width: 479px) {
  .buttonsWrapper {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
  }

  .bannerBackgroundGradient {
    width: 100%;
    top: 36%;
  }
}