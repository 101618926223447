.webContainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.webContainer:before, .webContainer:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.webContainer:after {
  clear: both;
}

.container {
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media screen and (max-width: 479px) {
  .webContainer {
    max-width: none;
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
  }

  .webContainer {
    max-width: 728px;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    width: 100%;
  }
}