@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Mulish, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --text-dark: #2f1936;
  --paragraph-gray: #534a57;
  --primary-reach: #e98a6d;
  --primary-violet: #7c53d3;
  --light-gray: #f9f9fa;
  --paragraph-light: #e7d8f0;
  --white: white;
  --transparent: rgba(255, 255, 255, 0);
  --dark-bg: #220c3d;
  --dark-gray: #afafaf;
  --black: black;
  --white-50: rgba(255, 255, 255, .5);
  --paragraph-dark: #050038;
  --accent: #ff358a;
  --dark-gray-50: rgba(175, 175, 175, .5);
}