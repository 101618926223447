.h6 {
  margin-bottom: 10px;
  font-weight: bold;
}

.h6 {
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
}

.h6 {
  color: var(--text-dark);
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2em;

  &.white {
    color: var(--white);
  }
}

@media screen and (min-width: 1440px) {
  .h6 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 991px) {
  .h6 {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .h6 {
    font-size: 18px;
  }
}