.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.bannerOurValues {
  background-color: var(--primary-violet);
  background-image: linear-gradient(rgba(0, 0, 0, 0), #481260), url('Our_Values_bg.webp');
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  background-attachment: scroll, scroll;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 155px;
  padding-bottom: 80px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1920px) {

}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .section.bannerOurValues {
    padding-top: 195px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.bannerOurValues{
    padding-top: 105px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }
}